<template>
  <div class="mine_container">
    <div class="mine_top">
      <div class="setting" @click="$router.push('/setting')">设置 <van-icon name="setting-o" /></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Mine',
  computed: {},
  data () {
    return {}
  },
  created () {},
  methods: {}
}
</script>

<style lang="scss" scoped>
  @import "../../styles/mixin";

  .mine_container {
    .mine_top {
      position: relative;
      background: linear-gradient(to right, #009bff, #0087ff);
      padding: 36px 0 126px 36px ;
      .setting {
        float: right;
        @include sc(28px, #fff);
        margin-bottom: 26px;
        margin-right: 36px;
        @include extendClick;
      }
    }
  }
</style>
